import api from "../config/api.json";
export class Api {
  constructor(keycloak) {
    this.keycloak = keycloak;
  }

  getData(url, mediaType = "application/json") {
    const tokenUpdate = this.keycloak.updateToken();
    const requestUrl = this.getRequestUrl(url);
    return tokenUpdate.then(() => {
      return fetch(requestUrl, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
          "Content-Type": "application/json",
          Accept: mediaType,
        },
      }).then((response) => response.json());
    });
  }
  postData(url, body, mediaType = "application/json") {
    const tokenUpdate = this.keycloak.updateToken();
    const requestUrl = this.getRequestUrl(url);
    return tokenUpdate.then(() => {
      return fetch(requestUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
          "Content-Type": "application/json",
          Prefer: "params=single-object",
          Accept: mediaType,
        },
        body,
      });
    });
  }

  updateData(url, body) {
    const tokenUpdate = this.keycloak.updateToken();
    const requestUrl = this.getRequestUrl(url);
    return tokenUpdate.then(() => {
      return fetch(requestUrl, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
    })

  }
  telemetryTest() {
    return this.getData(api.telemetry_test);
  }
  // setProperties(1,{propertis:{...}})
  setProperties(id, properties) {
    const params = new URLSearchParams();
    params.append("id", `eq.${id}`);
    const requestUrl = `${api.properties}?${params.toString()}`;
    this.updateData(this.getRequestUrl(requestUrl), properties)
  }

  setGeoStyle(style) {
    this.updateData(this.getRequestUrl(api.style), style);
  }

  setMeter(id, meter) {
    const params = new URLSearchParams();
    params.append("object_id", `eq.${id}`);
    const requestUrl = `${api.meters}?${params.toString()}`;
    return this.updateData(this.getRequestUrl(requestUrl), meter);
  }

  setCsvUpload(csv) {
    console.log(csv)
    const tokenUpdate = this.keycloak.updateToken();
    const requestUrl = this.getRequestUrl(api.upload_csv);
    return tokenUpdate.then(() => {
      return fetch(requestUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
          "Content-Type": "application/octet-stream"
        },
        body: csv,
      });
    });
  }

  getSingleObject(url, name) {
    const response = this.getData(url, "application/vnd.pgrst.object+json");
    return response.then((response) => response[name]);
  }

  getUserRole() {
    return this.keycloak.tokenParsed.preferred_username;
  }
  getResources() {
    return this.getData(api.resources)
  }

  getProperties() {
    return this.getSingleObject(api.properties, "properties");
  }
  getNames() {
    return this.getData(api.names);
  }
  getGeostyle() {
    return this.getData(api.geostyle);
  }

  getCadastre() {
    return this.getSingleObject(api.cadastre, "cadastre");
  }

  setSensorsArea(body) {
    return this.postData(api.sensorsArea, body);
  }

  getTelemetry(request) {
    const params = new URLSearchParams();
    params.append("id", request.id);
    params.append("start_date", request.startDate);
    params.append("end_date", request.endDate);
    const requestUrl = `${api.telemetry}?${params.toString()}`;
    return this.getData(requestUrl);
  }
  getMetersDma(request) {
    const params = new URLSearchParams();
    params.append("wkt_geom", request.wktGeom);
    params.append("query_year", request.queryYear);
    params.append("query_month", request.queryMonth);
    const requestUrl = `${api.metersDma}?${params.toString()}`;
    return this.getData(requestUrl);
  }
  getMeter(request) {
    const params = new URLSearchParams();
    params.append("id", request.id);
    params.append("query_date", request.queryDate);
    const requestUrl = `${api.meter}?${params.toString()}`;
    return this.getData(requestUrl);
  }

  getTelemetryQuery(request) {
    const params = new URLSearchParams();
    params.append("id", request.id);
    params.append("query_date", request.queryDate);
    const requestUrl = `${api.telemetry}?${params.toString()}`;
    return this.getData(requestUrl);
  }

  getGeoJsonData(url) {
    return this.getData(url, "application/geo+json");
  }

  getRequestUrl(url) {
    return new URL(url, this.keycloak.tokenParsed.api).href;
  }
  csvExport() {
    const requestUrl = `${api.meters_export}`;
    return this.getData(requestUrl);
  }

}