export default {
  'mm': {to_meter: 0.001},
  'cm': {to_meter: 0.01},
  'ft': {to_meter: 0.3048},
  'us-ft': {to_meter: 1200 / 3937},
  'fath': {to_meter: 1.8288},
  'kmi': {to_meter: 1852},
  'us-ch': {to_meter: 20.1168402336805},
  'us-mi': {to_meter: 1609.34721869444},
  'km': {to_meter: 1000},
  'ind-ft': {to_meter: 0.30479841},
  'ind-yd': {to_meter: 0.91439523},
  'mi': {to_meter: 1609.344},
  'yd': {to_meter: 0.9144},
  'ch': {to_meter: 20.1168},
  'link': {to_meter: 0.201168},
  'dm': {to_meter: 0.01},
  'in': {to_meter: 0.0254},
  'ind-ch': {to_meter: 20.11669506},
  'us-in': {to_meter: 0.025400050800101},
  'us-yd': {to_meter: 0.914401828803658}
};
